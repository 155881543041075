import * as React from "react"
import { Link } from "gatsby"
import { StaticImage} from "gatsby-plugin-image"

import Marquee from "react-fast-marquee"

const Testimonials = () => {

    return (
       
<section className ="testimonails">

<div className ="container">
  <h1>Customer Stories</h1>
</div>
<Marquee pauseOnHover = {true}>

<div className = "client_data">

  
<figure className="snip1321">

<StaticImage
src="../images/test_place.png"
quality = "90"
width ="300"
alt="A Gatsby astronaut"
placeholder="blurred" />


<h3>Beauty of Math</h3>
<h1>james</h1>
<figcaption>

<h4>Vice President</h4>
<blockquote>Communication was clear and helpful and resolved all our issues. Quality of the work was outstanding, full marks. </blockquote>
</figcaption><a href="#"></a>
</figure>

<iframe width="560" height="315" src="https://www.youtube.com/embed/CIYqb6rZeow" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

<figure className="snip1321">

<StaticImage
src="../images/test_place.png"
quality = "90"
width ="300"
alt="A Gatsby astronaut"
placeholder="blurred" />


<h3>Lela's Design</h3>
<h1>Gisela</h1>
<figcaption>

<h4> President   </h4>
<blockquote>They did an excellent job. Neat, professional and organized, we’re very pleased with the results. We’ll definitely re-hire them for a future contract and recommend their services to everyone. </blockquote>
</figcaption><a href="#"></a>
</figure>
<iframe width="560" height="315" src="https://www.youtube.com/embed/72PR6FptXnY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

<figure className="snip1321">

<StaticImage
src="../images/test_place.png"
quality = "90"
width ="300"
alt="A Gatsby astronaut"
placeholder="blurred" />


<h3>Stellar</h3>
<h1>Paul</h1>
<figcaption>

<h4>Director   </h4>
<blockquote>Great team. We tried numerous other web development resources prior and were not pleased. This was different. Every step of the way was pleasant and professional. If you are reading this and wondering if you should hire them, READ NO FURTHER!! CLICK the "Get Started" button. </blockquote>
</figcaption><a href="#"></a>
</figure>
<iframe width="560" height="315" src="https://www.youtube.com/embed/xjw2e3yMn8U" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

<figure className="snip1321">

<StaticImage
src="../images/test_place.png"
quality = "90"
width ="300"
alt="A Gatsby astronaut"
placeholder="blurred" />


<h3>Vbamerica</h3>
<h1>Robert</h1>
<figcaption>

<h4> Director Assitant   </h4>
<blockquote>We’ll definitely recommend them, very flexible and adaptable. Always available and professional. </blockquote>
</figcaption><a href="#"></a>
</figure>



<iframe width="560" height="315" src="https://www.youtube.com/embed/34hXICAaTs8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>



<figure className="snip1321">

<StaticImage
src="../images/test_place.png"
quality = "90"
width ="300"
alt="A Gatsby astronaut"
placeholder="blurred" />


<h3>Feerwear</h3>
<h1>Robert</h1>
<figcaption>

<h4> Director Assitant   </h4>
<blockquote>Estore Company did exactly what we asked for. We’re extremely impressed and satisfied. Thank you so much! </blockquote>
</figcaption><a href="#"></a>
</figure>



<figure className="snip1321">

<StaticImage
src="../images/test_place.png"
quality = "90"
width ="300"
alt="A Gatsby astronaut"
placeholder="blurred" />


<h3>Greeneum </h3>
<h1>Ben </h1>
<figcaption>

<h4> Director Assitant   </h4>
<blockquote>They again brought professionalism and expertise to make a fantastic looking storefront. Patient and quick to make any tweaks, truly a gem of a find. </blockquote>
</figcaption><a href="#"></a>
</figure>


<figure className="snip1321">

<StaticImage
src="../images/test_place.png"
quality = "90"
width ="300"
alt="A Gatsby astronaut"
placeholder="blurred" />


<h3>kramproof </h3>
<h1>Alex Von </h1>
<figcaption>

<h4> Director Assitant   </h4>
<blockquote>Estore Company delivered nice work on developing the ecommerce store in a short amount of time. Their skills are reasonably strong and communication response times were good. </blockquote>
</figcaption><a href="#"></a>
</figure>



<figure className="snip1321">

<StaticImage
src="../images/test_place.png"
quality = "90"
width ="300"
alt="A Gatsby astronaut"
placeholder="blurred" />


<h3>Heavenroast </h3>
<h1>JJ </h1>
<figcaption>

<h4> Director Assitant   </h4>
<blockquote>Working with Estore Company was great. Work is really good and professional, and it gets done super quickly. Always available when needed, and a pleasure to work with. Highly recommend.  </blockquote>
</figcaption><a href="#"></a>
</figure>






</div>




</Marquee>



</section>













        )



    }
    
    export default Testimonials;




