import * as React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import Banner from "../components/banner"
import Case_study from "../components/case_study"
import Testimonials from "../components/testimonials"
import Seo from "../components/seo"

import { StaticImage, getImage ,GatsbyImage} from "gatsby-plugin-image"
import CountUp from 'react-countup'
import 'react-animated-slider/build/horizontal.css'


const banner_title  = ['Growth', 3000, 'Potential ', 2000, 'Success ',1500];
const IndexPage = (ecommerce_services) => {



  const home_data  = ecommerce_services.data.wpPage.homePage_metaData;

  const ecommerce_service = ecommerce_services.data.allWpEcommerceService;
  const case_study_slider = ecommerce_services.data.allWpCaseStudySlider;




console.log('Home', ecommerce_services.data);


return (
<Layout>
<Seo title="One Stop Solution for all the requirements that make your Ecommerce Business Successful. " />
<Banner title = {banner_title} sticky_first = "Unlock" sticky_second  = "Your Ecommerce" />

<section className = "inro" >
<div className = "container">
  <p>{home_data.introduction} </p>
</div>
</section>



<section className = "ecommerce_services">

{ecommerce_service.edges.map(service => (

    <div className = "service" key = {service.node.slug}>
      <Link to = {`/ecommerce-service/${service.node.slug}`}>
    <GatsbyImage
      image= {getImage(service.node.ecommerceServices.serviceTitleImage.localFile)}
      quality = "90"
      layout="fullWidth"
      alt="A Gatsby astronaut"
      placeholder="blurred"
    />
      <h2>{service.node.title}</h2>
      </Link>
    </div>

))}

  
 

</section>


<section className = "who_we_are"  >

    <div className ="container">

      <div className = "who_content par" >
<div dangerouslySetInnerHTML={{ __html:home_data.whoWeAre }} ></div>
         
          <Link to ="/about-us" className = "btn">read more</Link> 
      </div>

      <div className = "counter_series par">

        <ul>
            <li>
              <div>
              <StaticImage
      src="../images/icon-001.png"
      quality = "90"
       alt="A Gatsby astronaut" 
       placeholder = "tracedSVG"
       />
       

<CountUp
  start={0}
  end={11}
  delay={0}
  duration={10}

>
  {({ countUpRef, start }) => (
    
      <span ref={countUpRef} />
    
  
  )}
</CountUp>
       
              </div>
          <div>
      <span>+</span>
    <span>years of industry experience</span>
 </div>
 </li>

 

 <li>
              <div>
              <StaticImage
      src="../images/icon-003.png"
      quality = "90"
       alt="A Gatsby astronaut"
       placeholder = "tracedSVG"
       />
      <CountUp
  start={0}
  end={57}
  delay={0}
  duration={10}

>
  {({ countUpRef, start }) => (
    
      <span ref={countUpRef} />
    
  
  )}
</CountUp>
              </div>
          <div>
      <span>+</span>
    <span>active clients</span>
 </div>
 </li>

 <li>
              <div>
              <StaticImage
      src="../images/icon-002.png"
      quality = "90"
       alt="A Gatsby astronaut"
       placeholder = "tracedSVG"
       />
      <CountUp
  start={0}
  end={15}
  delay={0}
  duration={10}

>
  {({ countUpRef, start }) => (
    
      <span ref={countUpRef} />
    
  
  )}
</CountUp>
              </div>
          <div>
      <span>+</span>
    <span>inhouse professionals</span>
 </div>
 </li>
 <li>
              <div>
              <StaticImage
      src="../images/icon-004.png"
      quality = "90"
       alt="A Gatsby astronaut"
       placeholder = "tracedSVG"
       />
      <CountUp
  start={0}
  end={507}
  delay={0}
  duration={10}

>
  {({ countUpRef, start }) => (
    
      <span ref={countUpRef} />
    
  
  )}
</CountUp>
              </div>
          <div>
      <span>+</span>
    <span>completed projects</span>
 </div>
 </li>
        </ul>


      </div>
<div className = "batches">

  <ul>


    {
      
      home_data.partnershipBatches.map(batch => {

      return   <li key = {batch.batchTitle}>
        
        
<GatsbyImage
      image={getImage(batch.addNewBatch.localFile)}
      alt ={batch.batchTitle} />
    </li>


      })
 }
  



  </ul>

</div>

    </div>


    

</section>


<Case_study  data_case_slider = {case_study_slider} />



<section className = "imagine">

<StaticImage
      src="../images/collage-bg.jpg"
      quality = "90"
      layout="fullWidth"
      alt="A Gatsby astronaut"
      placeholder="blurred"
      />

    <div className = "inner_intro" dangerouslySetInnerHTML={{ __html:home_data.deepIntroduction }}> 
     
    </div>

</section>

<section className ="full_services">

      <div className ="container">

       <div dangerouslySetInnerHTML={{ __html:home_data.fullServices.heading }}></div>
        <ul>

{

home_data.fullServices.services.map(service => {


return  <li className = "first" key = {service.title}>
  <h3>{service.title} </h3>
  <p>{service.detail}</p>
      
</li>

})


}


        </ul>
      </div>
</section>




<section className ="industry_verticals">

    <div className ="container">

<div dangerouslySetInnerHTML={{ __html:home_data.industryVerticals.heading }}></div>
   
          <ul>
     


     {

home_data.industryVerticals.industryVerticalList.map(industry => {

    return  <li>
 <GatsbyImage
      image={getImage(industry.image.localFile)}
      alt ={industry.title} />




    <h2>{industry.title}</h2>

    </li>

})


     }


          </ul>



    </div>


</section>

<Testimonials />


<section className ="trusted">


    <div className = "brands_list">
      <h1>You’re in a good company </h1>

      <ul>

        {



home_data.leadingBrands.brandList.map(brand => {

  return  <li>
   <GatsbyImage
      image={getImage(brand.brandImage.localFile)}
      alt ={brand.brandName} />
  
  </li>


})
        }
      

      </ul>

    </div>

      <div className ="brand_view">


      {

<GatsbyImage
image={getImage(home_data.leadingBrands.commonImage.localFile)}
alt = "trusted"
/>


 }

      </div>

</section>


<section className = "get_touch">

    <div className ="container">

      <h5>get in touch</h5>
      <h1>You’re missing the ecommerce magic. Let’s unlock it.</h1>
      <Link to ="/contact-us" className = "a_btn">get started </Link>
    </div>


</section>







  </Layout>
)
  }
export const services = graphql`
query allWordpressPost {
  allWpEcommerceService {
    edges {
      node {
        slug
        title
        ecommerceServices {
          serviceTitleImage {
            id
            localFile {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, blurredOptions: {toFormat: AUTO},quality: 100)
              }
            }
          }
        }
      }
    }
  }

  allWpCaseStudySlider {
    edges {
      node {
        caseStudySlider {
          projectName
          projectStack
          projectLogo {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED,  width: 70,quality: 100)
              }
            }
          }
          projectImage {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED,quality: 100)
              }
            }
          }
        }
      }
    }
  }

  wpPage(isFrontPage: {eq: true}) {
    title
    homePage_metaData {
      introduction
      whoWeAre
      partnershipBatches {
        batchTitle
        addNewBatch {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, quality: 100)
            }
          }
        }
      }
      deepIntroduction
      fullServices {
        heading
        services {
          title
          detail
        }
      }
      industryVerticals {
        heading
        industryVerticalList {
          title
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, quality: 100,  width: 70)
              }
            }
          }
        }
      }
      leadingBrands {
        commonImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, quality: 100)
            }
          }
        }
        brandList {
          brandName
          brandImage {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, quality: 100, width:100)
              }
            }
          }
        }
      }
    }
  }
}



  `
export default IndexPage
